<template>
	<div>
		<b-modal size="xl" ref="modal" hide-footer>
			<template v-slot:modal-title>
				<template v-if="isTiersContact">
					<template v-if="ajouter">
						{{ $t("tiers.ajouter_contact_tiers") }}
					</template>

					<template v-if="modifier">
						{{ $t("tiers.modifier_contact_tiers") }}
					</template>
				</template>
				<template v-if="isHorseContact">
					<template v-if="ajouter">
						{{ $t("horse.ajouter_contact_cheval") }}
					</template>

					<template v-if="modifier">
						{{ $t("horse.modifier_contact_cheval") }}
					</template>
				</template>
				<template v-if="!isHorseContact && !isTiersContact">
					<template v-if="modifier">
						{{ $t("global.modifier_contact") }}
					</template>
					<template v-if="ajouter">
						{{ $t("global.ajouter_contact") }}
					</template>
				</template>
			</template>

			<div v-if="!modal_loaded">
				<LoadingSpinner class="col-12" />
			</div>

			<div v-if="modal_loaded">
				<form @submit="checkForm" id="addTiers">
					<div class="row">
						<div class="col-12" v-if="form_message !== ''">
							<ErrorAlert :messageI18n="form_message" />
						</div>

						<div class="col-12" v-if="ajouter">
							<div class="form-group">
								<label for="contact_choice">{{ $t("contact.alreay_exist") }}</label>
								 <e-select 
	                            	v-model="contact_choice"
		                            id="contact_choice"
		                            track-by="contact_id"
		                            label="contact_label"
		                            :placeholder="$t('monte.selectionnez_contact')"
		                            :selectedLabel="$t('global.selected_label')"
		                            :options="contact_formatted"
		                            :loading="loadingContact"

		                            :searchable="true"
		                            :allow-empty="true"
		                            :show-labels="false"
				                >
				                	<template slot="option" slot-scope="{ option }">{{ option.contact_label }}</template>
				               		<template slot="singleLabel" slot-scope="{ option }">{{ option.contact_label }}</template>
									<template slot="noOptions">{{ $t('global.list_empty') }}</template>
			               		</e-select>
			               	</div>
			               	<div class="text-center">
								<b-button v-if="contact_choice" variant="primary" @click.prevent="addExistingContact">{{ $t("global.ajouter") }} <font-awesome-icon :icon="['fal', 'user-plus']"/></b-button>
							</div>
						</div>

						<div class="col-12" >
							<div class="form-group">
								<label for="contact_civility" class="col-form-label">{{ $t("contact.civility") }} *</label>
								<e-select
									id="contact_civility"
									v-model="form.contact_civility"
									:options="civilities"
									:allow-empty="false"
									:show-labels="false"
								/>
							</div>
							<div class="row">
								<div class="col-6">
									<div class="form-group">
										<label for="contact_firstname" class="col-form-label">{{ $t("contact.firstname") }}</label>
										<input type="text" id="contact_firstname" class="form-control" v-model="form.contact_firstname">
									</div>
								</div>
								<div class="col-6">
									<div class="form-group">
										<label for="contact_lastname" class="col-form-label">{{ $t("contact.lastname") }}</label>
										<input type="text" id="contact_lastname" class="form-control" v-model="form.contact_lastname">
									</div>
								</div>
							</div>
						</div>
					</div>

					<div class="row">
						<div class="col-12">
							<div class="form-group">
								<label for="contact_address1" class="col-form-label">{{ $t("contact.address1") }}</label>
								<input type="text" id="contact_address1" class="form-control" v-model="form.contact_address1">
							</div>
						</div>
						<div class="col-12">
							<div class="form-group">
								<label for="contact_address2" class="col-form-label">{{ $t("contact.address2") }}</label>
								<input type="text" id="contact_address2" class="form-control" v-model="form.contact_address2">
							</div>
						</div>
						<div class="col-4">
							<div class="form-group">
								<label for="contact_postalcode" class="col-form-label">{{ $t("contact.postalcode") }}</label>
								<input type="text" id="contact_postalcode" class="form-control" v-model="form.contact_postalcode">
							</div>
						</div>
						<div class="col-8">
							<div class="form-group">
								<label for="contact_town" class="col-form-label">{{ $t("contact.town") }}</label>
								<input type="text" id="contact_town" class="form-control" v-model="form.contact_town">
							</div>
						</div>
						<div class="col-12">
							<div class="form-group">
								<label for="contact_country" class="col-form-label">{{ $t("contact.country") }}</label>
								<CountryInput v-model="form.contact_country"></CountryInput>
							</div>
						</div>
					</div>

					<div class="row">
						<div class="col-12">
							<div class="form-group">
								<label for="contact_mail" class="col-form-label">{{ $t("contact.email") }}</label>
								<input type="email" id="contact_mail" class="form-control" v-model="form.contact_mail">
							</div>
						</div>
					</div>

					<div class="row">
						<div class="col-6">
							<div class="form-group">
								<label for="contact_datenaissance" class="col-form-label">{{ $t("contact.datenaissance") }}</label>
								<e-datepicker id="contact_datenaissance" v-model="form.contact_datenaissance" />
							</div>
						</div>
						<div class="col-6">
							<div class="form-group">
								<label for="contact_commentaire" class="col-form-label">{{ $t("contact.commentaire") }}</label>
								<input type="text" id="contact_commentaire" class="form-control" v-model="form.contact_commentaire">
							</div>
						</div>
					</div>


					<div class="row">
						<div class="col-12">
							<label class="col-form-label">{{ $t("contact.phone") }}</label>
						</div>

						<div class="col-12 phone mb-3" v-for="(phone, key_phone) in form.phones" v-bind:key="key_phone">
							<div class="row no-gutters">
								<div class="col-auto pr-2">
									<phoneInput v-model="form.phones[key_phone]"></phoneInput>
								</div>
								<div class="col pr-2">
									<div class="input-group">
										<div class="input-group-prepend">
											<b-form-input type="text" class="form-control" style="width: 5em;" v-model="phone.phone_indicatif"/>
										</div>
										<input type="text" class="form-control" v-model="phone.phone_numero" required>
									</div>
								</div>
								<div class="col-3">
									<e-select
										id="phone_type"
										v-model="phone.phone_type"
										:options="[
											$t('tiers.phone_type.fixe'),
											$t('tiers.phone_type.portable')
										]"
										:allow-empty="false"
										:show-labels="false"
									/>
								</div>
							</div>
						</div>
						<div class="col-12 text-right">
							<a href="#" @click.prevent="addPhoneNumber()">{{ $t('global.ajouter_numero_telephone') }} <font-awesome-icon :icon="['fal', 'phone-plus']"/></a>
						</div>
					</div>

					<div class="row">
						<div class="col-12" v-if="isTiersContact || isHorseContact">
							<div class="form-group">
								<label for="tierscontact_poste" class="col-form-label">{{ $t("contact.poste") }}</label>
								<e-select
                                    v-model="fonctions_formatted_flat"
                                    id="tierscontact_poste"
                                    track-by="name"
                                    label="name"
                                    :placeholder="labelFonctionTiersContactVat"
                                    :deselectLabel="deselectLabel"
                                    :selectedLabel="selectedLabel"
                                    :selectLabel="enter_to_select"
                                    :options="fonctions"
                                    :searchable="true"
                                    :show-labels="false"
                                    :allow-empty="true"
                                    :multiple="true"
                                >
                                    <template slot="singleLabel" slot-scope="{ option }">{{ option.name }}</template>
                                    <template slot="noOptions">{{ $t('global.list_empty') }}</template>
                                </e-select>
							</div>
						</div>

						<div class="col-12" v-if="boolean_fonction">
							<div class="form-group">
								<label>{{ $t("contact.fonction_perso") }}</label>
								<input
									type="text"
									class="form-control"
									v-model="fonction_perso[key].name"
									v-for="(perso, key) in fonction_perso"
									:key="key"
								>
							</div>
						</div>
						<div class="col-12 text-right">
							<a href="#" v-if="isTiersContact || isHorseContact" @click.prevent="addFonctionInput()">{{ $t('contact.ajouter_fonction') }} <font-awesome-icon :icon="['fal', 'user-visor']"/></a>
						</div>

						<div class="col-12">
							<div class="form-group">
	                            <b-form-checkbox
									class="custom-control"
									v-model="form.contact_operateur" 
									name="check-button" 
									switch
								>
	                                {{ $t("contact.operateur") }}
								</b-form-checkbox>
	                        </div>
	                    </div>
	                    <template v-if="form.contact_operateur" >
		                    <div class="col-12">
		                    	<div class="form-group">
									<label for="contact_num_ordre" class="col-form-label">{{ $t("contact.num_ordre") }}</label>
									<input type="email" id="contact_num_ordre" class="form-control" v-model="form.contact_num_ordre">
								</div>
		                    </div>
		                    <div class="col-6">
		                    	<div class="form-group">
									<label for="contact_abbreviation" class="col-form-label">{{ $t("contact.abbreviation") }}</label>
									<input type="text" id="contact_abbreviation" minlength="1" maxlength="2" class="form-control" v-model="form.contact_abbreviation">
								</div>
		                    </div>
		                    <div class="col-6">
		                    	<div class="form-group">
									<label for="contact_color" class="col-form-label">{{ $t("contact.color") }}</label>
									<b-form-input type="color" id="contact_color" v-model="form.contact_color"></b-form-input>
								</div>
		                    </div>
	                    </template>
					</div>

				</form>

				<div class="text-center">
					<b-button v-if="ajouter" variant="primary" @click.prevent="checkForm">{{ $t("global.ajouter") }} <font-awesome-icon :icon="['fal', 'user-plus']"/></b-button>
					<b-button v-if="modifier" variant="primary" @click.prevent="checkForm">{{ $t("global.modifier") }} <font-awesome-icon :icon="['fal', 'user-plus']"/></b-button>
				</div>
			</div>
		</b-modal>
	</div>
</template>

<script type="text/javascript">
	import Vue from 'vue'

	import Countries from '@/assets/lang/countries/countries'
	import Indicatif from '@/assets/lang/countries/indicatif'

	import Navigation from "@/mixins/Navigation.js"
	import Tools from "@/mixins/Tools.js"
	import Contact from '@/mixins/Contact.js'
	import Phone from '@/mixins/Phone.js'
	import Fonction from '@/mixins/Fonction.js'
	import Tiers from '@/mixins/Tiers.js'
	import Horse from "@/mixins/Horse.js"
    import Common from '@/assets/js/common.js'

	export default {
		name: "ContactForm",
		props: {
			accountingplan_id: { type: Number, default: 0},
			from: { type: String, default: '' },
			tiers: { type: Object, default: () => { return {} }},
			horse: { type: Object, default: () => { return {} }},
			callback: { type: Function, default: () => { return false }}
		},
		mixins: [Navigation, Tools, Contact, Phone, Fonction, Tiers, Horse],
		data () {
			return {
				deselectLabel: this.getTrad("global.press_enter_to_remove"),
				selectedLabel: this.getTrad("global.selected_label"),
				enter_to_select: this.getTrad("global.press_enter_to_select"),
				labelFonctionTiersContactVat: this.getTrad("contact.search_fonction"),
				isTiersContact: false,
				isHorseContact: false,
				modal_loaded: false,
				ajouter: false,
				modifier: false,
				form_message: "",
				errors: [],
				form: {},
				countries: {},
				country_choosen: false,
				fonctions: [],
				fonctions_formatted_flat: [],
				default_form: {
					contact_firstname: '',
					contact_lastname: '',
					contact_civility: 'Mr',
					contact_mail: '',
					contact_address1: '',
					contact_address2: '',
					contact_postalcode: '',
					contact_town: '',
					contact_country: this.getConfig('country', '75'),
					contact_website: '',
					contact_entity: false,
					tierscontact_poste: "",
					tierscontact_favoris: false,
					contact_operateur: false,
					contact_num_ordre: '',
					contact_abbreviation: '',
					contact_color: '',
					contact_commentaire: '',
					contact_datenaissance: null,
					phones: [
						{
							phone_indicatif: '+33',
							phone_numero: '',
							phone_type: this.getTrad('tiers.phone_type.fixe'),
							phone_country: "FRA"
						},
						{
							phone_indicatif: '+33',
							phone_numero: '',
							phone_type: this.getTrad('tiers.phone_type.portable'),
							phone_country: "FRA"
						}
					],
					fonctions_formatted: []
				},
				boolean_fonction: false,
				fonction_perso: [
					{
						name: ""
					}
				],
				contact_existing: [],
				contact_choice: null,
				loadingContact: false,
				civilities: Common.getAllCivilities(),
				events_tab: {
					'TableAction::goToAddContact': (params) => {

						if(params.tiers_id) {
							this.isHorseContact = false
							this.isTiersContact = true
							this.addOrEditContact(params.tiers_id, null)
						} else if(params.horse_id) {
							this.isTiersContact = false
							this.isHorseContact = true
							this.addOrEditContact(null, params.horse_id)
						}
						else {
							this.addOrEditContact(null, null)
						}
					},
					'TableAction::goToEditContact': (params) => {
						if(params.tiers_id) {
							this.isHorseContact = false
							this.isTiersContact = true
							this.addOrEditContact(params.tiers_id, null, params.contact_id)
						} else if(params.horse_id) {
							this.isTiersContact = false
							this.isHorseContact = true
							this.addOrEditContact(null, params.horse_id, params.contact_id)
						} else {
							this.isTiersContact = false
							this.isHorseContact = false
							this.addOrEditContact(null, null, params.contact_id)
						}
					},
					'TableAction::goToDeleteContact': (params) => {
						if(params.tiers_id) {
							this.deleteContact(params.tiers_id, null, params.contact_id)
						} else if(params.horse_id) {
							this.deleteContact(null, params.horse_id, params.contact_id)
						}
					}
				}
			}
		},
		mounted() {
			this.init_component()
		},
		methods: {
			async init_component() {
				let fonctions = await this.loadFonction()
				for(var i = 0; i < fonctions.length; i++) {
					let label = this.getTrad(fonctions[i].fonction_label)
					if(fonctions[i].fonction_code == 'COMPTA'){
						label += ' (' + this.getTrad('fonction.comptabilite_desc') + ')'
					} else if (fonctions[i].fonction_code == 'RESPELEV') {
						label += ' (' + this.getTrad('fonction.responsable_elevage_desc') + ')'
					}
					this.fonctions.push({name: label, id: fonctions[i].fonction_id })
				}

				this.contact_existing = await this.getAllContact()
			},
			async addOrEditContact(tiers_id, horse_id, contact_id, tiers = null) {
				if (tiers == null && this.from == "tiers") return
				this.form_message = ""
				this.modal_loaded = false
				this.$refs['modal'].show()
				this.indicatifs = Indicatif
				this.countries = Countries[Vue.i18n.locale()].label
				this.countries_iso = Countries[Vue.i18n.locale()].iso_3
				this.fonction_perso = [{
					name: ""
				}]

				this.boolean_fonction = false
				this.form = this.deppCloneObj(this.default_form)
				if(contact_id != undefined) {
					this.ajouter = false
					this.modifier = true
					if(this.$parent.filtredContacts) {
						this.form = this.deppCloneObj(this.$parent.filtredContacts.filter(contact => contact.contact_id == contact_id)[0])
					}
					else {
						this.form = this.$parent.contact
					}
					this.fonctions_formatted_flat = []
					if(tiers_id || horse_id) {
						for(var i = 0; i < this.form.fonctions.length; i++) {
							this.fonctions_formatted_flat.push({name: this.getTrad(this.form.fonctions[i].fonction_label), id: this.form.fonctions[i].fonction_id })
						}
					}
				} else {
					this.fonctions_formatted_flat = []
					this.ajouter = true
					this.modifier = false
					if (this.from == "tiers" && tiers) {
						this.isTiersContact = true
						const name = tiers.tiers_firstname + tiers.tiers_lastname
						this.form = {
							contact_address1:	tiers.tiers_address1,
							contact_address2:	tiers.tiers_address2,
							contact_civility:	tiers.tiers_civility,
							contact_country: tiers.tiers_country,
							contact_firstname: tiers.tiers_firstname,
							contact_lastname: tiers.tiers_lastname,
							contact_mail: tiers.tiers_mail,
							contact_postalcode: tiers.tiers_postalcode,
							contact_town: tiers.tiers_town,
							contact_abbreviation: name ? name.slice(0,2).toUpperCase() : tiers.tiers_alias,
							phones: tiers.phones,
						}
					} else if(!tiers_id) {
						const countries_iso = Countries[Vue.i18n.locale()].iso_3
						const country = this.form.contact_country ?? this.getConfig('country', '75');
						const ISO = countries_iso[parseInt(country)]
						for (let phone of this.form.phones) {
							phone.phone_indicatif = Indicatif[ISO]
							phone.phone_country = ISO
						}
					}

				}

				this.form.tiers_id = tiers_id
				this.form.horse_id = horse_id
				this.country_choosen = true
				this.modal_loaded = true
			},
			async deleteContact(tiers_id, horse_id, contact_ids) {
				await this.invalidContact(contact_ids, horse_id, tiers_id)
				this.$parent.loadContacts()
			},
			async checkForm() {
				this.form_message = ""
				this.errors = []
				if(!this.form.contact_civility) {
					this.errors.push("civility")
				}

				if(this.errors.length == 0) {
					let result = null
					let params_to_send = {
						"contact": this.form,
						"fonction_perso": this.fonction_perso
					}

					if(this.isTiersContact) {
						try {
							if(this.form.contact_id) {
								await this.editTiersContactFunction(this.form.tiers_id, this.form.contact_id, params_to_send)
							} else {
								await this.addTiersContactFunction(this.form.tiers_id, params_to_send)
							}

							this.$refs['modal'].hide()
							this.$parent.loadContacts()
						} catch (error) {
							console.error(error)
							this.form_message = "error.LEP"
						}
					} else if(this.isHorseContact) {
						try {
							if(this.form.contact_id) {
								await this.editHorseContactFunction(this.form.horse_id, this.form.contact_id, params_to_send)
							} else {
								await this.addHorseContactFunction(this.form.horse_id, params_to_send)
							}

							this.$refs['modal'].hide()
							this.$parent.loadContacts()
						} catch (error) {
							console.error(error)
							this.form_message = "error.LEP"
						}
					} else {
						try {
							if(this.form.contact_id) {
								await this.editContactFunction(this.form.contact_id, params_to_send)
							}
							else {
								const contact_id = await this.addContact(params_to_send.contact)
								const phones = params_to_send.contact.phones
								if(phones) {
									for(let i = 0; i < phones.length; i++) {
										if(phones[i].phone_numero) {
											phones[i].contact_id = contact_id
											phones[i].tiers_id = null
											await this.addPhone(phones[i])
										}
									}
								}
							}

							this.$refs['modal'].hide()
							if(this.from == "tiers"){
								this.successToast("toast.info_save_succes")
							}
							else if(!this.$parent.contact) {
								this.$parent.loadContacts()
							}
						} catch (error) {
							console.error(error)
							this.form_message = "error.LEP"
						}
					}
				} else {
					this.form_message = "formulaire.erreur_champs_non_remplis"
				}
			},
			async addTiersContactFunction(tiers_id, params_to_send) {
				const db = this.$storage.db
				// await db.transaction('rw', [db.contact, db.phone, db.tiers_contact, db.tiers_contact_fonction, db.fonction], async () => {
					const contact_id = await this.addContact(params_to_send.contact)
					const phones = params_to_send.contact.phones
					if(phones) {
						for(let i = 0; i < phones.length; i++) {
							if(phones[i].phone_numero) {
								phones[i].contact_id = contact_id
								phones[i].tiers_id = null
								await this.addPhone(phones[i])
							}
						}
					}

					const tierscontact_id = await this.addTiersContact(tiers_id, contact_id)

					const fonctions = params_to_send.contact.fonctions_formatted
					if(fonctions) {
						for (let y = 0; y < fonctions.length; y++) {
							await this.addTiersContactFonction(fonctions[y].id, tierscontact_id)
						}
					}

					const fonctions_perso = params_to_send.fonction_perso
					let fonction_id = null
					if(fonctions_perso) {
						for (var i = 0; i < fonctions_perso.length; i++) {
							if(fonctions_perso[i].name) {
								fonction_id = await this.addFonction({fonction_label: fonctions_perso[i].name, fonction_code: ''})
								await this.addTiersContactFonction(fonction_id, tierscontact_id)
							}
						}
					}
				// });
			},
			async editTiersContactFunction(tiers_id, contact_id, params_to_send) {
				const db = this.$storage.db
				// await db.transaction('rw', [db.contact, db.phone, db.tiers_contact, db.tiers_contact_fonction, db.fonction], async () => {
					const params = {
						contact_lastname: params_to_send.contact.contact_lastname,
						contact_firstname: params_to_send.contact.contact_firstname,
						contact_civility: params_to_send.contact.contact_civility,
						contact_mail: params_to_send.contact.contact_mail,
						contact_address1: params_to_send.contact.contact_address1,
						contact_address2: params_to_send.contact.contact_address2,
						contact_postalcode: params_to_send.contact.contact_postalcode,
						contact_town: params_to_send.contact.contact_town,
						contact_country: params_to_send.contact.contact_country,
						contact_operateur: params_to_send.contact.contact_operateur ? 1 : 0,
						contact_num_ordre: params_to_send.contact.contact_num_ordre,
						contact_commentaire: params_to_send.contact.contact_commentaire,
						contact_datenaissance: params_to_send.contact.contact_datenaissance
					}
					this.editContact(contact_id, params)
					this.deleteAllPhone(contact_id)
					const phones = params_to_send.contact.phones
					if(phones) {
						for(let i = 0; i < phones.length; i++) {
							if(phones[i].phone_numero) {
								phones[i].contact_id = contact_id
								phones[i].tiers_id = null
								this.addPhone(phones[i])
							}
						}
					}

					const tierscontact = await this.loadTiersContact(tiers_id, contact_id)
					if(tierscontact) {
						this.deleteAllTiersContactFonction(tierscontact[0].tierscontact_id)
						const fonctions = params_to_send.contact.fonctions_formatted
						if(fonctions) {
							for (let y = 0; y < fonctions.length; y++) {
								this.addTiersContactFonction(fonctions[y].id, tierscontact[0].tierscontact_id)
							}
						}

						const fonctions_perso = params_to_send.fonction_perso
						let fonction_id = null
						if(fonctions_perso) {
							for (var i = 0; i < fonctions_perso.length; i++) {
								if(fonctions_perso[i].name) {
									fonction_id = await this.addFonction({fonction_label: fonctions_perso[i].name, fonction_code: ''})
									this.addTiersContactFonction(fonction_id, tierscontact[0].tierscontact_id)
								}
							}
						}
					}
				// });
			},
			async addHorseContactFunction(horse_id, params_to_send) {
				const db = this.$storage.db
				// await db.transaction('rw', [db.contact, db.phone, db.horse_contact, db.horse_contact_fonction, db.fonction], async () => {
					const contact_id = await this.addContact(params_to_send.contact)
					const phones = params_to_send.contact.phones
					if(phones) {
						for(let i = 0; i < phones.length; i++) {
							if(phones[i].phone_numero) {
								phones[i].contact_id = contact_id
								phones[i].tiers_id = null
								await this.addPhone(phones[i])
							}
						}
					}

					const horsecontact_id = await this.addHorseContact(horse_id, contact_id)

					const fonctions = params_to_send.contact.fonctions_formatted
					if(fonctions) {
						for (let y = 0; y < fonctions.length; y++) {
							await this.addHorseContactFonction(fonctions[y].id, horsecontact_id)
						}
					}

					const fonctions_perso = params_to_send.fonction_perso
					let fonction_id = null
					if(fonctions_perso) {
						for (var i = 0; i < fonctions_perso.length; i++) {
							if(fonctions_perso[i].name) {
								fonction_id = await this.addFonction({fonction_label: fonctions_perso[i].name, fonction_code: ''})
								await this.addHorseContactFonction(fonction_id, horsecontact_id)
							}
						}
					}
				// });
			},
			async editHorseContactFunction(horse_id, contact_id, params_to_send) {
				const db = this.$storage.db
				// await db.transaction('rw', [db.contact, db.phone, db.horse_contact, db.horse_contact_fonction, db.fonction], async () => {
					const params = {
						contact_lastname: params_to_send.contact.contact_lastname,
						contact_firstname: params_to_send.contact.contact_firstname,
						contact_civility: params_to_send.contact.contact_civility,
						contact_mail: params_to_send.contact.contact_mail,
						contact_address1: params_to_send.contact.contact_address1,
						contact_address2: params_to_send.contact.contact_address2,
						contact_postalcode: params_to_send.contact.contact_postalcode,
						contact_town: params_to_send.contact.contact_town,
						contact_country: params_to_send.contact.contact_country,
						contact_operateur: params_to_send.contact.contact_operateur ? 1 : 0,
						contact_num_ordre: params_to_send.contact.contact_num_ordre
					}
					this.editContact(contact_id, params)
					this.deleteAllPhone(contact_id)
					const phones = params_to_send.contact.phones
					if(phones) {
						for(let i = 0; i < phones.length; i++) {
							if(phones[i].phone_numero) {
								phones[i].contact_id = contact_id
								phones[i].tiers_id = null
								this.addPhone(phones[i])
							}
						}
					}

					const horsecontact = await this.loadHorseContact(horse_id, contact_id)
					if(horsecontact) {
						this.deleteAllHorseContactFonction(horsecontact[0].horsecontact_id)
						const fonctions = params_to_send.contact.fonctions_formatted
						if(fonctions) {
							for (let y = 0; y < fonctions.length; y++) {
								this.addHorseContactFonction(fonctions[y].id, horsecontact[0].horsecontact_id)
							}
						}

						const fonctions_perso = params_to_send.fonction_perso
						let fonction_id = null
						if(fonctions_perso) {
							for (var i = 0; i < fonctions_perso.length; i++) {
								if(fonctions_perso[i].name) {
									fonction_id = this.addFonction({fonction_label: fonctions_perso[i].name, fonction_code: ''})
									this.addHorseContactFonction(fonction_id, horsecontact[0].horsecontact_id)
								}
							}
						}
					}
				// });
			},
			async editContactFunction(contact_id, params_to_send) {
				const db = this.$storage.db
				// await db.transaction('rw', [db.contact, db.phone], async () => {
					const params = {
						contact_lastname: params_to_send.contact.contact_lastname,
						contact_firstname: params_to_send.contact.contact_firstname,
						contact_civility: params_to_send.contact.contact_civility,
						contact_mail: params_to_send.contact.contact_mail,
						contact_address1: params_to_send.contact.contact_address1,
						contact_address2: params_to_send.contact.contact_address2,
						contact_postalcode: params_to_send.contact.contact_postalcode,
						contact_town: params_to_send.contact.contact_town,
						contact_country: params_to_send.contact.contact_country,
						contact_operateur: params_to_send.contact.contact_operateur ? 1 : 0,
						contact_num_ordre: params_to_send.contact.contact_num_ordre,
						contact_color: params_to_send.contact.contact_color,
						contact_abbreviation: params_to_send.contact.contact_abbreviation,
						contact_datenaissance: params_to_send.contact.contact_datenaissance,
						contact_commentaire: params_to_send.contact.contact_commentaire
					}
					this.editContact(contact_id, params)
					this.deleteAllPhone(contact_id)
					const phones = params_to_send.contact.phones
					if(phones) {
						for(let i = 0; i < phones.length; i++) {
							if(phones[i].phone_numero) {
								phones[i].contact_id = contact_id
								phones[i].tiers_id = null
								this.addPhone(phones[i])
							}
						}
					}
				// })
			},
			addPhoneNumber() {
				const countries_iso = Countries[Vue.i18n.locale()].iso_3
				const ISO = countries_iso[parseInt(this.form.contact_country)]

				this.form.phones.push({
					phone_indicatif: Indicatif[ISO],
					phone_numero: '',
					phone_type: this.getTrad('tiers.phone_type.fixe'),
					phone_country: ISO,
				})
			},
			indicatif(country, key_phone) {
				this.form.phones[key_phone].phone_indicatif = this.indicatifs[country]
				this.form.phones[key_phone].phone_country = country
			},
			addFonctionInput() {
				if(this.fonction_perso.length >= 1 && this.boolean_fonction) {
					this.fonction_perso.push({name:""})	
				}

				this.boolean_fonction = true
			},

			async addExistingContact() {
				if(this.isHorseContact) {
					await this.addHorseContact(this.form.horse_id, this.contact_choice.contact_id)
				} 
				else if(this.isTiersContact) {
					await this.addTiersContact(this.form.tiers_id, this.contact_choice.contact_id)
				}
				this.$refs['modal'].hide()
				this.$parent.loadContacts()
			},

			calculAbbreviation() {
				if(this.form.contact_abbreviation.length < 2) {
					let name = this.form.contact_firstname + this.form.contact_lastname
					this.form.contact_abbreviation = name.slice(0,2).toUpperCase()
				}
			}
		},
		computed: {
			contact_formatted: function() {
				return this.contact_existing.map(contact => {
					let contact_label = contact.contact_firstname + ' ' + contact.contact_lastname
					if(contact.phones.length > 0) contact_label += ' (' + contact.phones[0].phone_combine + ')'

					return {contact_id: contact.contact_id, contact_label: contact_label}
				})
			}
		},

		watch: {
		    'fonctions_formatted_flat'(val) {
		    	this.form.fonctions_formatted = this.fonctions_formatted_flat
	        },
		    'form.contact_firstname'(val) {
		    	if (val) this.calculAbbreviation()
	        },
		    'form.contact_lastname'(val) {
				if (val) this.calculAbbreviation()
	        },
			'form.contact_operateur'(val) {
				if (val === 1) {
					this.form.contact_operateur = true;
				} else {
					this.form.contact_operateur = val;
				}
			},
			'form.contact_country' (val) {
				const countries_iso = Countries[Vue.i18n.locale()].iso_3
				const ISO = countries_iso[parseInt(val)]
				for (let phone of this.form.phones) {
					if (!phone.phone_numero || phone.phone_numero === "") {
						phone.phone_indicatif = Indicatif[ISO]
						phone.phone_country = ISO
					}
				}
			}
		},
		components: {
			LoadingSpinner : () => import('GroomyRoot/components/Logos/LoadingSpinner_35'),
			ErrorAlert : () => import('GroomyRoot/components/Alert/ErrorAlert'),
			PhoneInput : () => import('GroomyRoot/components/Inputs/PhoneInput'),
			CountryInput : () => import('GroomyRoot/components/Inputs/CountryInput'),
		}
	}
</script>
